import styled from 'styled-components';

export const ChatbotContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    // background: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    // box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);
    padding: 20px;
    backdrop-filter: blur(10px);

 @media (max-width: 768px) {
        width: 90%;
        height: 70%;
        bottom: 50px;
        right: 5%;
        left: 5%;
        border-radius: 10px;
    }

    @media (max-width: 480px) {
        width: 100%;
        height: 87%;
        bottom: 0px;
        right: 0px;
        left: 0px;
        border-radius: 0px;
    }
`;

export const MessagesContainer = styled.div`
    width: 110%;
    max-width: 800px;
    height: 100vh; /* Adjust height to fit in the smaller window */
    // background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    overflow-y: auto;
    backdrop-filter: blur(10px);
`;

export const Message = styled.div`
    background: ${props => props.sender === 'user' ? 'linear-gradient(135deg, #667eea, #764ba2)' : 'rgba(255, 255, 255, 0.2)'};
    color: ${props => props.sender === 'user' ? 'white' : 'white'};
    padding: 15px 20px;
    border-radius: 20px;
    margin-bottom: 10px;
    align-self: ${props => props.sender === 'user' ? 'flex-end' : 'flex-start'};
    max-width: 80%;
    box-shadow: ${props => props.sender === 'user' ? '0 4px 8px rgba(0, 0, 0, 0.2)' : '0 8px 32px rgba(31, 38, 135, 0.37)'};
    animation: fadeIn 0.5s ease;
    line-height: 1.6;
    text-align: justify;
    margin-left: ${props => props.sender === 'user' ? 'auto' : '0'};
    margin-right: ${props => props.sender === 'user' ? '0' : 'auto'};
    position: relative;
    backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    &.markdown {
        line-height: 1.4;
        text-align: justify;
    }

    & p {
        margin: 1em 0;
    }

    & ul, & ol {
        padding-left: 20px;
        margin: 0.5em 0;
    }

    & li {
        margin-bottom: 0.5em;
    }

    & h2, & h3 {
        margin-top: 1em;
    }
`;

export const InputContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 800px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    position: absolute;
    bottom: -68px;
`;

export const Input = styled.input`
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 50px;
    font-size: 16px;
    outline: none;
    background: transparent;
    color: grey;

    ::placeholder {
        color: #e0e0e0;
    }
`;

export const SendButton = styled.button`
    padding: 10px;
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-left: 10px;

    &:hover {
        background-color: #357abd;
    }
`;

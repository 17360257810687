import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  ChatbotContainer,
  MessagesContainer,
  Message,
  InputContainer,
  Input,
  SendButton
} from './ChatbotStyles';
import TypewriterEffect from './TypewriterEffect';
import { IoSend } from 'react-icons/io5';
import './scrollbar.css';

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [typing, setTyping] = useState(false);
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL + '/chat';

  const sendMessage = async () => {
    if (!input.trim()) return;

    const userMessage = { text: input, sender: 'user' };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInput('');
    setTyping(true);

    try {
      const response = await axios.post(apiUrl, { message: input });
      const botMessage = { text: response.data.reply.trim(), sender: 'bot' };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error) {
      console.error(error);
    } finally {
      setTyping(false);
    }
  };

  const handleScroll = () => {
    if (messagesContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;
      const isAtBottom = scrollHeight === scrollTop + clientHeight;
      setIsAutoScrollEnabled(isAtBottom);
    }
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current && isAutoScrollEnabled) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <ChatbotContainer>
      <MessagesContainer
        id="messages-container"
        ref={messagesContainerRef}
        onScroll={handleScroll}
      >
        {messages.map((msg, index) => (
          <Message key={index} sender={msg.sender} className="markdown">
            {msg.sender === 'bot' ? (
              <TypewriterEffect text={msg.text} />
            ) : (
              msg.text
            )}
          </Message>
        ))}
        <div ref={messagesEndRef} />
      </MessagesContainer>
      <InputContainer>
        <Input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
          placeholder="Tapez votre message..."
        />
        <SendButton onClick={sendMessage}>
          <IoSend />
        </SendButton>
      </InputContainer>
    </ChatbotContainer>
  );
};

export default Chatbot;

import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const TypewriterEffect = ({ text, typingSpeed = 10, onTypingEnd }) => {
  const [displayedText, setDisplayedText] = useState('');


  useEffect(() => {
    let currentText = '';
    let index = 0;

    const type = () => {
      if (index < text.length) {
        currentText += text[index];
        setDisplayedText(currentText);
        index++;
        setTimeout(type, typingSpeed);
      } else if (onTypingEnd) {
        onTypingEnd();
      }
    };

    type();

    return () => {
      setDisplayedText('');
    };
  }, [text, typingSpeed, onTypingEnd]);

  return <ReactMarkdown remarkPlugins={[remarkGfm]}>{displayedText}</ReactMarkdown>;
};

export default TypewriterEffect;

import React from 'react';
import GlobalStyle from './GlobalStyles';
import styled from 'styled-components';
import ChatbotLauncher from './ChatbotLauncher';

const AppContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(135deg, #667eea, #764ba2);
    font-family: 'Roboto', sans-serif;
`;

function App() {
  console.log("Script React exécuté et rendu");
    return (
        <AppContainer>
            <GlobalStyle />
            <ChatbotLauncher />
        </AppContainer>
    );
}

export default App;

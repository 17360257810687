import React, { useState } from 'react';
import styled from 'styled-components';
import { IoChatbubbles } from 'react-icons/io5';
import Chatbot from './Chatbot';

const ChatbotLauncherButton = styled.button`
    position: fixed;
    bottom: 50px !important;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50% !important;
    background-color: #4a90e2;
    color: white;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    z-index: 1001;

    @media (max-width: 768px) {
    width: 50px;
    height: 50px;
  }
`;

const ChatbotContainer = styled.div`
    position: fixed;
    bottom: 184px;
    right: 30px;
    width: 450px;
    height: 570px;
    border-radius: 10px;
    background: linear-gradient(135deg, #667eea, #764ba2);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    display: ${props => (props.visible ? 'block' : 'none')};
    z-index: 1002;

  @media (max-width: 768px) {
    width: 90%;
    right: 5%;
    bottom: 20px;
    height: 80%;
  }

  @media (max-width: 480px) {
    width: 81%;
    right: 16px;
    bottom: 107px;
    height: 62%;
  }
`;

const ChatbotLauncher = () => {
    const [visible, setVisible] = useState(false);

    const toggleChatbot = () => {
      console.log('Toggling chatbot, current visibility:', visible);
      setVisible(!visible);
    };

    return (
        <>
            <ChatbotLauncherButton onClick={toggleChatbot} aria-label="Open chatbot">
            <IoChatbubbles />
            </ChatbotLauncherButton>
            <ChatbotContainer visible={visible}>
                <Chatbot />
            </ChatbotContainer>
        </>
    );
};

export default ChatbotLauncher;
